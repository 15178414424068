<template>
  <div class="calc-slider__wrapper">
    <swiper
      :slides-per-view="1"
      :centered-slides="false"
      :effect="'fade'"
      :space-between="20"

      @swiper="calcSliderSwiper.setSliderRef"
    >
      <swiper-slide
        v-for="promo in images"
        :key="`propmoi-home-${promo.image}`"
        class="calc-slider__slide"
      >
        <div class="calc-slider__img adaptive-img">
          <NuxtImg
            :src="String(promo.image)"
            width="840"
            height="691"
            fit="crop"
            loading="lazy"
            placeholder
          />
        </div>
      </swiper-slide>
    </swiper>
    <template v-if="images && images.length > 1">
      <div
        :class="{'swiper-button-disabled ': calcSliderSwiperRef?.isBeginning}"
        class="htbsi-slider-images__navigation left htbsi-slider-images__navigation--prev"
        @click="calcSliderSwiper.prevSlide()"
      >
        <img src="~/assets/img/svg/common/chevron-right-black.svg" />
      </div>
      <div
        :class="{'swiper-button-disabled ': calcSliderSwiperRef?.isEnd}"
        class="htbsi-slider-images__navigation right htbsi-slider-images__navigation--next"
        @click="calcSliderSwiper.nextSlide()"
      >
        <img src="~/assets/img/svg/common/chevron-right-black.svg" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { HowToBuyBlockSliderType } from '~/types/HowToBuyTypes';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';

const props = defineProps<{
	images: HowToBuyBlockSliderType[]
}>();
const calcSliderSwiperRef = ref<SwiperClass | null>(null);
const calcSliderSwiper = setCustomSwiper(calcSliderSwiperRef);
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.calc-slider__wrapper {
	height: 100%;
	position: relative;
}

.calc-slider__img {
	height: 100%;

	& > img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 30px;
	}
}

@include media('lg') {
	.calc-slider__wrapper {
		height: 100%;
	}
}

.htbsi-slider-images__navigation {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	background-color: white;
	cursor: pointer;

	img {
		filter: invert(18%) sepia(45%) saturate(1840%) hue-rotate(237deg) brightness(90%) contrast(109%);
	}

	&.left {
		left: 24px;

		img {
			transform: rotate(180deg);
		}
	}

	&.right {
		right: 24px;
	}

	&.swiper-button-disabled {
		cursor: not-allowed;
		background-color: #F5F4F8 !important;
	}

	&:hover {
		background-color: #FFC115;
	}
}

@include media('lg') {
	.htbsi-slider-images__navigation {
		&.left {
			left: 12px;
		}

		&.right {
			right: 12px;
		}
	}
}

@include media('md') {
	.htbsi-slider-images {
		margin: 0 -30px;
		padding: 0 30px;
		box-sizing: border-box;
		border-radius: 0;
		box-shadow: initial;

		&:after {
			content: "";
			float: left;
			padding-top: 85%;
		}
	}
	.htbsi-slider-images__slider .swiper-container {
		margin: 0 -30px;
		padding: 0 30px;
		box-sizing: border-box;
		width: calc(100% + 60px) !important;
	}
	.htbsi-slider-images__navigation {
		display: none;
	}
}

@include media('md') {

}

@include media('sm') {
	.htbsi-slider-images {
		margin: 0 -20px;
		padding: 0 20px;
	}
	.htbsi-slider-images__slider {
		width: 100%;
	}
	.htbsi-slider-images__slider .swiper-container {
		margin: 0 -20px;
		padding: 0 20px;
		width: calc(100% + 40px) !important;
		position: relative;
	}
}
</style>
