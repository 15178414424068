<template>
  <div>
    <div class="v-purchase-mortgage_banks-item">
      <div class="v-purchase-mortgage_bank-item">
        <div class="__partners">
          <nuxt-img
            loading="lazy"
            :src="String(bank?.imageId)"
            class="v-purchase-mortgage_bank-img "
            alt="img"
            placeholder
          />
        </div>

        <p class="v-purchase-mortgage_bank-text __rate">
          <span class="v-purchase-mortgage_subtitle">Ставка:</span>
          <span>от {{ bank?.interestRate }}%</span>
        </p>

        <p class="v-purchase-mortgage_bank-text __period">
          <span class="v-purchase-mortgage_subtitle">Срок:</span>
          <span>до {{ bank?.maxTerm }} лет</span>
        </p>

        <p class="v-purchase-mortgage_bank-text __payment">
          <span class="v-purchase-mortgage_subtitle">Платеж:</span>
          <span>от {{ $priceFormat({amount: setMinMonthlyPayment(bank!)}) }}
            р./мес.</span>
        </p>

        <div
          class="v-purchase-mortgage_bank-partners-btn"
          @click="openOffer(bank!)"
        >
          <img
            :class="{'active':currentBank?.name === bank?.name}"
            src="~/assets/img/svg/header/arrow-up-gray.svg"
            alt="arrow"
          >
        </div>
      </div>
      <div
        class="v-purchase-mortgage_bank-offer-block"
        :class="{ __active: currentBank?.name === bank?.name }"
      >
        <p class="fs-14 __bold v-purchase-mortgage_offer-text "><span v-html="setMessage"></span></p>

        <div
          class="v-purchase-mortgage_offer-btn btn primary --shadow"
          @click="openPopupCalc"
        >
          Оставить заявку
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { kalculateMonthlyPayment } from '~/utils';
import type { CurrentPaymentInfoType, ItemBankExtrasCalc } from '~/types/MainCalcBlockType';
import { useCallBackPopup } from '~/composables/useCallBackPopup';

const { $priceFormat } = useNuxtApp();
const props = defineProps<{
	bank: ItemBankExtrasCalc | null;
	currentBank: ItemBankExtrasCalc | null;
	filteredValues: CurrentPaymentInfoType;
}>();

const emit = defineEmits<{
	setCurrentBank: [item: ItemBankExtrasCalc | null]
}>();

const { openCallBackPopup } = useCallBackPopup();

const setMessage = computed(() => props?.bank?.message ||
	'Поможем с оформлением ипотеки, направим в несколько банков заявку.<br/> Наша результативность по одобрению 95%.');

const openOffer = (item: ItemBankExtrasCalc) => {
  if (props.currentBank === item) {
    emit('setCurrentBank', null);
    return;
  }
  emit('setCurrentBank', item);
};

const setMinMonthlyPayment = (bank: ItemBankExtrasCalc) => kalculateMonthlyPayment({
  cost: props.filteredValues.cost,
  firstPay: props.filteredValues.firstPay,
  interestRate: props?.bank?.interestRate,
  term: props.filteredValues.term
});

const openPopupCalc = () => {
  openCallBackPopup({
    type: 'Заявка на получение ипотеки',
    title: 'Поможем с оформлением.',
    subtitle: '95% одобрения ипотеки!'
  });
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.v-purchase-mortgage_bank-item {
	display: flex;
	padding: 30px 40px;
	align-items: center;
	border: 1px solid var(--primary-10);
	border-top: 1px solid transparent;
	transition: border 0.4s ease;

	&:hover {
		border: 1px solid var(--secondary)
	}

	& .__partners {
		width: 21%;
		margin-right: 6%;
		max-height: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		& > img {
			height: 100%;
		}
	}

	& .__rate {
		width: 12%;
		margin-right: 6%;
	}

	& .__period {
		width: 12%;
		margin-right: 6%;
	}

	& .__payment {
		width: 22%;
		margin-right: 2%;
	}
}

.v-purchase-mortgage_subtitle {
	display: none;
}

.v-purchase-mortgage_bank-img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: left;
}

.v-purchase-mortgage_bank-partners-btn {
	margin-left: auto;
	opacity: .6;
	cursor: pointer;
	padding: 10px;
	box-sizing: border-box;
	width: 32px;
	height: 32px;

	& > img {
		width: 100%;
		transform: rotate(180deg);

		&.active {
			transform: rotate(270deg);
		}
	}

	&:hover {
		opacity: 1;
	}
}

.v-purchase-mortgage_offer-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 24px;
}

.v-purchase-mortgage_bank-offer-block {
	display: none;
	padding: 24px 40px;
	background-color: var(--primary-10);
	position: relative;

	&.__active {
		display: flex;
		flex-direction: column;
	}

	&::before {
		position: absolute;
		content: '';
		bottom: 0;
		left: 40px;
		width: calc(100% - 80px);
		height: 1px;
		background-color: var(--gray-light)
	}

	&:last-child::before {
		display: none;
	}

	& .__offer-name {
		width: 21%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__rate {
		width: 8%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__period {
		width: 8.5%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__payment {
		width: 15%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .v-purchase-mortgage_offer-btn {
		//display: inline-block;
		//margin-right: auto;
		//border: 1px solid transparent;
	}
}

@include media('md') {
	.v-purchase-mortgage_bank-item {
		flex-wrap: wrap;
		padding: 20px 30px;
		position: relative;
		border: none;

		&:hover {
			border: none;
		}

		& .__partners {
			width: 100%;
			margin-bottom: 16px;
		}
	}

	.v-purchase-mortgage_bank-offer-block {
		padding: 20px 30px;
	}

	.v-purchase-mortgage_bank-partners-btn {
		position: absolute;
		top: 20px;
		right: 22px;
	}

	.v-purchase-mortgage_subtitle {
		display: block;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: var(--gray-light);
	}

	.v-purchase-mortgage_bank-text {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: initial;
		font-size: 14px;
		line-height: normal;
		color: var(--primary);
	}
}

@include media('sm') {
	.v-purchase-mortgage_bank-item {
		padding: 20px;
	}

	.v-purchase-mortgage_bank-offer-block {
		padding: 20px;
	}
}

</style>
