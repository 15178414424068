<template>
  <section>
    <div class="container">
      <div class="calc-block__root">
        <div
          :class="type"
          class="calc-block__wrapper"
        >
          <div class="calc-block__controls">
            <div v-if="Object.keys(initPaymentInfo).length">
              <CalcBlockControls
                :current-payment-info="currentPaymentInfo"
                :init-payment-info="initPaymentInfo"
                :values="values"
                @changeCurrentPaymentInfo="onChangeCurrentPaymentInfo"
              />
            </div>
          </div>
          <div class="class-block__banks">
            <!--            <div class="class-block__tabs">-->
            <!--              <div-->
            <!--                v-for="tab in tabsList"-->
            <!--                :key="`ket-banks-tab-id-${tab.id}`"-->
            <!--                :class="{'active': tab.id === currentTab.id}"-->
            <!--                class="class-block__tab"-->

            <!--                @click="onChangeCurrentTab(tab)"-->
            <!--              >-->
            <!--                {{ tab.name }}-->
            <!--              </div>-->
            <!--            </div>-->
            <div class=" calc-block__slider">
              <BanksList
                v-if="!values.sliders"
                :banks="banksList"
                :filtered-values="currentPaymentInfo"
              />

              <CalcBlockSlider
                v-else
                :images="values.sliders"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BanksList from '~/components/calc-block/BanksList.vue';

import type {
  CurrentPaymentInfoType,
  CurrentPaymentItemsType,
  ExtrasCalcBlock,
  InitPaymentInfoType,
  ItemBankExtrasCalc
} from '~/types/MainCalcBlockType';

import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { HowToBuyBlockValuesType } from '~/types/HowToBuyTypes';
import CalcBlockSlider from '~/components/calc-block/CalcBlockSlider.vue';

type IPravoberegMainCalculateBlockProps = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	extras: ExtrasCalcBlock;
	values: HowToBuyBlockValuesType;
	type: 'sliders' | 'banks'
}

const props = defineProps<IPravoberegMainCalculateBlockProps>();

const tabsList = ref<{ id?: string; name?: string; }[]>([]);
const currentTab = ref<{ id?: string; name?: string; }>({});

const initPaymentInfo = ref<InitPaymentInfoType>({});
const currentPaymentInfo = ref<CurrentPaymentInfoType>({});

const banksList = ref<ItemBankExtrasCalc[] | undefined>([]);

const getFirstPayDiapazon = computed(() => {
  const programList = props.extras.banks;

  const firstPayList: number[] = [];
  const cost = currentPaymentInfo.value.cost || 0;

  programList.forEach((bank) => {
    firstPayList.push(bank.firstPay);
  });

  const firstPay = Math.min.apply(null, firstPayList);

  const min = Number(Math.round(cost / 100 * firstPay));
  const max = cost;

  return { min, max };
});

const initCurrentInfo = () => {
  const initProgram = props.extras.banks;

  banksList.value = initProgram || [];
  if (!banksList.value.length) {
    return;
  }

  const costList = new Set<number>();
  const termList = new Set<number>();
  const interestRateList = new Set<number>();
  const firstPayList = new Set<number>();

  initProgram?.forEach((t) => {
    costList.add(t.minCost);
    costList.add(t.maxCost);

    termList.add(t.minTerm);
    termList.add(t.maxTerm);

    interestRateList.add(t.interestRate);
    firstPayList.add(t.firstPay);
  });

  initPaymentInfo.value.cost = Array.from(costList);
  currentPaymentInfo.value.cost = 5000000;

  initPaymentInfo.value.term = Array.from(termList);
  currentPaymentInfo.value.term = 30;

  initPaymentInfo.value.interestRate = Array.from(interestRateList);
  currentPaymentInfo.value.interestRate = Math.min.apply(null, Array.from(interestRateList));

  initPaymentInfo.value.firstPay = [getFirstPayDiapazon.value.min, getFirstPayDiapazon.value.max];
  currentPaymentInfo.value.firstPay = getFirstPayDiapazon.value.min;
};

const onChangeCurrentTab = (tab: { id?: string; name?: string; }) => {
  currentTab.value = tab;

  initCurrentInfo();
};
const onChangeCurrentPaymentInfo = (opt: { slug: CurrentPaymentItemsType; value: number }) => {
  currentPaymentInfo.value[opt.slug] = opt.value;
};

watch(() => currentPaymentInfo.value.cost, () => {
  currentPaymentInfo.value.firstPay = getFirstPayDiapazon.value.min;
  initPaymentInfo.value.firstPay = [getFirstPayDiapazon.value.min, getFirstPayDiapazon.value.max];
});

onMounted(() => {
  initCurrentInfo();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.calc-block__wrapper {
	display: flex;
	gap: 32px;
}

.calc-block__controls {
	width: 32%;

}

.calc-block__slider {
	height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
}

.class-block__banks {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 840px;
}

.class-block__tabs {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.class-block__tab {
	cursor: pointer;
	width: fit-content;
	padding: 5px 20px;
	border-radius: 25px;
	background: var(--gray-light);
	color: var(--dark-primary);
	font-size: 16px;
	font-weight: 500;
	line-height: 25px;
	border: 1px solid lightgray;
	transition: all .3s linear;

	&.active {
		background: var(--primary);
		color: var(--white);
		border: 1px solid transparent;
	}

	&:hover {
		border-color: var(--primary);
	}
}

.calc-block__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;

	& .left {
		color: var(--dark-primary);
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
	}

	& .right {
		color: var(--primary, #004685);
		font-size: 20px;
		font-weight: 600;
		line-height: 40px; /* 200% */
		text-transform: uppercase;
	}
}

@include media('lg') {
	.calc-block__wrapper {
		flex-direction: column;
		gap: 40px;

		&.sliders {
			flex-direction: column-reverse;
		}

	}

	.calc-block__title {
		& .right {
			display: none;
		}
	}

	.calc-block__controls {
		width: 100%;
	}
}

@include media('sm') {
	.calc-block__title {
		margin-bottom: 20px;
	}

	.class-block__tabs {
		padding: 0 20px;
	}

	.calc-block__wrapper {
		margin-left: -5px;
		margin-right: -5px;
		gap: 10px;

		&.banks .class-block__banks {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}
</style>
