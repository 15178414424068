<template>
  <div class="flex flex-col justify-between h-full">
    <div class="v-purchase-mortgage_banks-top__border">
      <div class="v-purchase-mortgage_banks-top">
        <p class=" v-purchase-mortgage_banks-top-text __partners">Банки партнеры</p>
        <p class=" v-purchase-mortgage_banks-top-text __rate">Ставка</p>
        <p class=" v-purchase-mortgage_banks-top-text __period">Срок</p>
        <p class=" v-purchase-mortgage_banks-top-text __payment">Платеж</p>
      </div>

      <template v-for="(bank,idx) in onFilterBank">
        <BankCardInfo
          v-if="idx < showBanksAmount"
          :key="`bank-list-${idx}`"
          :bank="bank"
          :current-bank="currentBank"
          :filtered-values="filteredValues"
          @setCurrentBank="setCurrentBank"
        />
      </template>
    </div>

    <div class="v-purchase-mortgage__allBanks">
      <button
        v-if="isShowButton && onFilterBank && onFilterBank.length > showBanksAmount"
        class="btn primary --shadow"
        @click="showAllBanks"
      >
        Все банки партнеры
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import BankCardInfo from '~/components/calc-block/BankCardInfo.vue';
import type { CurrentPaymentInfoType, ItemBankExtrasCalc } from '~/types/MainCalcBlockType';

const props = defineProps<{
	banks: ItemBankExtrasCalc[] | undefined;
	filteredValues: CurrentPaymentInfoType;
}>();
const currentBank = ref<ItemBankExtrasCalc | null>(null);
const showBanksAmount = ref<number>(5);
const isShowButton = ref(true);

const onFilterBank = computed(() => {
  const filter = props.filteredValues;

  if (!props.banks || !filter) {
    return;
  }

  return [...props.banks]
    .filter((item) => {
      const isTerm = Boolean(filter.term! <= item.maxTerm && filter.term! >= item.minTerm);
      const isCost = Boolean(item.minCost <= filter.cost! && filter.cost! <= item.maxCost);
      const isInterestRate = Boolean(filter.interestRate! <= item.interestRate);

      return Boolean(isInterestRate && isCost && isTerm);
    });
});

const setCurrentBank = (bank: ItemBankExtrasCalc | null) => {
  currentBank.value = bank;
};

const showAllBanks = () => {
  showBanksAmount.value = props?.banks?.length || 0;
  isShowButton.value = false;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.v-purchase-mortgage_banks-top {
	padding: 8px 40px;
	display: flex;
	background: var(--primary-10);
	border-radius: 10px 10px 0 0;
}

.v-purchase-mortgage_banks-bottom {
	height: 4px;
	display: flex;
	background: var(--primary-15);
	border-radius: 0 0 10px 10px;
}

.v-purchase-mortgage_banks-top-text {
	color: var(--primary-60);
	font-size: 12px;
	line-height: 16px;

	&.__partners {
		width: 21%;
		margin-right: 6%;
	}

	&.__rate {
		width: 12%;
		margin-right: 6%;
	}

	&.__period {
		width: 12%;
		margin-right: 6%;
	}

	&.__payment {
		width: 22%;
	}
}

.v-purchase-mortgage_bank-item {
	display: flex;
	padding: 30px 40px;
	align-items: center;
	border: 1px solid var(--primary);
	border-top: 1px solid transparent;
	transition: border 0.4s ease;

	&:hover {
		border: 1px solid var(--primary);
	}

	& .__partners {
		width: 21%;
		margin-right: 6%;
	}

	& .__rate {
		width: 8%;
		margin-right: 6%;
	}

	& .__period {
		width: 8.5%;
		margin-right: 6%;
	}

	& .__payment {
		width: 15%;
		margin-right: 6%;
	}
}

.v-purchase-mortgage_bank-partners-btn {
	position: relative;
	padding-right: 17px;
	cursor: pointer;

	&::before {
		position: absolute;
		content: '';
		right: 0;
		top: 4px;
		width: 7px;
		height: 7px;
		border-bottom: 1px solid var(--primary);
		border-right: 1px solid var(--primary);
		transform: rotate(45deg);
	}

	&:hover {
		&::before {
			border-bottom: 1px solid var(--primary);
			border-right: 1px solid var(--primary);
		}
	}

	& .__partners {
		width: 21%;
		margin-right: 6%;
	}

	& .__rate {
		width: 8%;
		margin-right: 6%;
	}

	& .__period {
		width: 8.5%;
		margin-right: 6%;
	}

	& .__payment {
		width: 15%;
		margin-right: 6%;
	}
}

.v-purchase-mortgage_bank-offer-block {
	display: none;
	align-items: center;
	padding: 24px 40px;
	background-color: var(--primary-10);
	position: relative;

	&.__active {
		display: flex;
	}

	&::before {
		position: absolute;
		content: '';
		bottom: 0;
		left: 40px;
		width: calc(100% - 80px);
		height: 1px;
		background-color: var(--primary);
	}

	&:last-child::before {
		display: none;
	}

	& .__offer-name {
		width: 21%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__rate {
		width: 8%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__period {
		width: 8.5%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .__payment {
		width: 15%;
		letter-spacing: 0.02em;
		margin-right: 6%;
	}

	& .v-purchase-mortgage_offer-btn {
		padding: 11px 29px;
	}
}

.v-purchase-mortgage__allBanks {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

@include media('md') {
	.v-purchase-mortgage_banks-top__border {
		& > div {
			border-top: 1px solid var(--primary);
		}

		& > div:last-child {
			border-bottom: 1px solid var(--primary);
		}
	}

	.v-purchase-mortgage_banks-top {
		display: none;
	}

	.v-purchase-mortgage__allBanks {
		margin-top: 20px;
	}

	.v-purchase-mortgage_banks-bottom {
		display: none;
	}
}

</style>
