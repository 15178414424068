import type { CurrentPaymentInfoType } from '~/types/MainCalcBlockType';

const kalculateMonthlyPayment = (paymentInfo: CurrentPaymentInfoType) => {
  const { cost = 0, firstPay = 0, interestRate = 0, term = 0 } = paymentInfo;
  const i = interestRate / 100 / 12;
  const n = term * 12;
  const r = (cost - firstPay) * ((i * (1 + i) ** n) / ((1 + i) ** n - 1));
  return Math.round(r);
};

export {
  kalculateMonthlyPayment
};
