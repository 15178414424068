<template>
  <div class="v-range">
    <label class="v-range_title">{{ label }}</label>
    <div class="v-range_input-block">
      <input
        v-model="currentValueInput"
        v-maska:[optionsInput]
        :data-maska="isNotInteger ? '9 99#.#': '9 99#'"
        data-maska-tokens="9:[0-9]:repeated"
        data-maska-reversed

        class="input-filter __right"
        @blur="onChangeInput"
      />
    </div>

    <Slider
      v-model="currentValueRange"
      :min="rangeMinMax.valueMin"
      :max="rangeMinMax.valueMax"
      :tooltips="true"
      :step="step"
      :format="{
        decimals:isNotInteger ? 1 : 0
      }"

      show-tooltip="drag"
      tooltip-position="bottom"
      @end="changeRange"
    />

    <!--    <div class="v-range_bottom">-->
    <!--      <p class="v-range_count __min">-->
    <!--        {{ $priceFormat({amount: rangeMinMax.valueMin, decimalCount: 1}) }}-->
    <!--      </p>-->
    <!--      <p class="v-range_count __max">-->
    <!--        {{ $priceFormat({amount: rangeMinMax.valueMax, decimalCount: 1}) }}-->
    <!--      </p>-->
    <!--    </div>-->
  </div>
</template>

<script setup lang="ts">
import Slider from '@vueform/slider';
import type { CurrentPaymentItemsType } from '~/types/MainCalcBlockType';

type RangeMinMaxValueType = {
	valueMin?: number;
	valueMax?: number;
}

const { $priceFormat } = useNuxtApp();
const props = defineProps<{
	valueList: number[] | undefined;
	step: number;
	currentValueProps: number | undefined;
	label: string;
	isNotInteger?: boolean;
	valueName: CurrentPaymentItemsType;
}>();

const emit = defineEmits<{
	changeCurrentPaymentInfo: [options: { slug: CurrentPaymentItemsType; value: number }]
}>();
const replaceSpacingString = (str: string | number = '') => String(str).replace(/\s/g, '');

const currentValueRange = ref(40);
const rangeMinMax = ref<RangeMinMaxValueType>({});

const currentValueInput = ref(String(currentValueRange.value));

const onEmitValue = () => {
  emit('changeCurrentPaymentInfo', {
    slug: props.valueName,
    value: currentValueRange.value
  });
};

const changeRange = () => {
  onEmitValue();
};
const onChangeInput = (event: Event) => {
  const { value } = (event.target as HTMLInputElement);

  const valueClear = Number.parseFloat((value || '').replace(/ /g, ''));
  if (rangeMinMax.value.valueMax && valueClear > rangeMinMax.value.valueMax) {
    currentValueInput.value = String(rangeMinMax.value.valueMax);
  }

  if (rangeMinMax.value.valueMin && valueClear < rangeMinMax.value.valueMin) {
    currentValueInput.value = String(rangeMinMax.value.valueMin);
  }

  currentValueRange.value = Number(replaceSpacingString(currentValueInput.value));
  onEmitValue();
};

const optionsInput = {
  preProcess: (val: number | string) => val,
  postProcess: (val: number | string) => {
    if (!val) {
      return rangeMinMax.value.valueMin;
    }
    return val;
  }
};

const initRange = () => {
  if (props.currentValueProps === undefined) {
    return;
  }
  if (props.valueList) {
    rangeMinMax.value.valueMin = Math.min.apply(null, props.valueList);
    rangeMinMax.value.valueMax = Math.max.apply(null, props.valueList);
  }

  currentValueRange.value = Number(replaceSpacingString(props.currentValueProps));
};

watch(currentValueRange, () => {
  currentValueInput.value = String(currentValueRange.value);
});

watch(() => props.currentValueProps, () => {
  if (props.currentValueProps) {
    initRange();
  }
});

onMounted(() => {
  nextTick(() => {
    initRange();
  });
});

</script>
<style lang="scss" scoped>
.v-range_title {
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 17.6px */
	letter-spacing: -0.32px;
	margin-bottom: 9px;
	display: inline-block;
	color: var(--primary);
}

.v-range_input-block {
	height: 45px;
}

.input-filter {
	padding: 0 20px;
	height: 45px;
}
</style>
