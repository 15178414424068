<template>
  <div class="calc-block__control">
    <div class="title">Рассчитайте комфортный платеж по ипотеке</div>
    <VRangeCalculator
      value-name="cost"
      label="Стоимость квартиры, ₽"
      :step="1"
      :value-list="initPaymentInfo.cost"
      :current-value-props="currentPaymentInfo.cost"

      @changeCurrentPaymentInfo="onChangeCurrentPaymentInfo"
    />

    <VRangeCalculator
      value-name="firstPay"
      label="Первоначальный взнос, ₽"
      :step="1000"
      :value-list="initPaymentInfo.firstPay"
      :current-value-props="currentPaymentInfo.firstPay"

      @changeCurrentPaymentInfo="onChangeCurrentPaymentInfo"
    />

    <VRangeCalculator
      value-name="term"
      label="Срок кредитования, лет"
      :step="1"
      :value-list="initPaymentInfo.term"
      :current-value-props="currentPaymentInfo.term"

      @changeCurrentPaymentInfo="onChangeCurrentPaymentInfo"
    />

    <VRangeCalculator
      value-name="interestRate"
      label="Минимальная ставка, %"
      is-not-integer
      :step="0.1"
      :value-list="initPaymentInfo.interestRate"
      :current-value-props="currentPaymentInfo.interestRate"

      @changeCurrentPaymentInfo="onChangeCurrentPaymentInfo"
    />

    <div class="calc-controls__bottom">
      <div class="calc-controls__price">
        <div class="calc-controls__info">
          <span class="item">Ежемесячный платеж, ₽</span>
          <span class="value">{{ $priceFormat({amount: getMinMonthlyPayment}) }}</span>
        </div>
        <div class="calc-controls__info">
          <span class="item">Сумма кредита, ₽</span>
          <span class="value">	{{ $priceFormat({amount: totalCreditAmount}) }}</span>
        </div>
      </div>

      <div class="calc-controls__btns">
        <button
          class="calc-controls__btn btn primary --shadow"
          @click="openPopupCalc"
        >
          Оставить заявку
        </button>
        <span>Расчёт кредита не является публичной офертой</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VRangeCalculator from '~/components/calc-block/VRangeCalculator.vue';
import type { CurrentPaymentInfoType, CurrentPaymentItemsType, InitPaymentInfoType } from '~/types/MainCalcBlockType';
import { useCallBackPopup } from '#imports';
import type { HowToBuyBlockValuesType } from '~/types/HowToBuyTypes';

const props = defineProps<{
	currentPaymentInfo: CurrentPaymentInfoType;
	initPaymentInfo: InitPaymentInfoType;
	values?: HowToBuyBlockValuesType
}>();

const emit = defineEmits<{
	changeCurrentPaymentInfo: [options: { slug: CurrentPaymentItemsType; value: number }]
}>();

const getMinMonthlyPayment = computed(() => kalculateMonthlyPayment(props.currentPaymentInfo));

const totalCreditAmount = computed(() => {
  const amountOfCredit = (props.currentPaymentInfo.cost || 0) - (props.currentPaymentInfo.firstPay || 0);
  if (amountOfCredit <= 0) {
    return 0;
  }

  return amountOfCredit;
});
const { $priceFormat } = useNuxtApp();
const { openCallBackPopup } = useCallBackPopup();

const onChangeCurrentPaymentInfo = (options: { slug: CurrentPaymentItemsType; value: number }) => {
  emit('changeCurrentPaymentInfo', options);
};

const stringMessage = () => {
  if (!props.currentPaymentInfo) {
    return;
  }
  return `Заявка на ипотеку, на сумму: ${$priceFormat({ amount: props.currentPaymentInfo.cost })} руб
	сроком на ${props.currentPaymentInfo.term} лет,
	с процентной ставкой ${props.currentPaymentInfo.interestRate}%,
	и первым взносом в размере ${$priceFormat({ amount: props.currentPaymentInfo.firstPay })}`;
};

const openPopupCalc = () => {
  openCallBackPopup({
    type: props?.values?.reach_goal_send_form,
    title: props?.values?.form_bank_title,
    subtitle: props?.values?.form_bank_message,
    message: stringMessage()
  });
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.calc-block__control {
	border-radius: 23px;
	border: 2px solid var(--secondary);
	padding: 32px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	background: var(--primary-10);

	& > .title {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 118%; /* 28.32px */
		letter-spacing: -0.96px;
		color: var(--primary);
	}
}

.calc-controls__btns {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;

	& > span {
		font: var(--font-family-secondary);
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 110%; /* 11px */
		letter-spacing: -0.2px;
	}
}

.calc-controls__btn {
	margin-top: 6px;
	width: 100%;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 15.238px; /* 84.656% */
	letter-spacing: -0.36px;
	height: 45px;
}

.calc-controls__bottom {
	background: var(--white);
	border-radius: 26px;
	padding: 20px 20px 10px;
	border: 1px solid var(--primary-15);
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.calc-controls__price {
	display: flex;
	gap: 35px;
}

.calc-controls__info {
	display: flex;
	flex-direction: column;
	gap: 8px;

	& > .item {
		font-family: var(--font-family-secondary);
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: -0.32px;
		color: var(--primary-60);
		max-width: 95px;
	}

	& > .value {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 118%; /* 28.32px */
		letter-spacing: -0.96px;
		color: var(--primary);
	}
}

@include media('sm') {
	.calc-block__control {
		padding: 40px 20px 30px;
		border-color: var(--primary-15);

		& > .title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 118%;
		}
	}

	.calc-controls__bottom {
		padding: 20px 20px 10px;
	}

}
</style>
